import { createContext, useContext } from "react"

export const DynamicComponentContext = createContext(null)

export const useDynamicComponents = () => {
  const getComponent = useContext(DynamicComponentContext)

  if (!getComponent) {
    throw new Error("DynamicComponentContext is required")
  }

  return getComponent
}

import React from "react"
import type { VFC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BlogPostCard } from "../BlogPostCard"
import { GridLayout } from "../../layout/GridLayout"

export const BlogPostsList: VFC = () => {
  const postsData = useStaticBlogPosts()

  return (
    <GridLayout gap="2rem">
      {postsData.items.map(postData => {
        return (
          <BlogPostCard
            key={postData.full_slug}
            post={{
              linktype: "story",
              cached_url: postData.full_slug,
              story: postData,
            }}
          />
        )
      })}
    </GridLayout>
  )
}

function useStaticBlogPosts() {
  return useStaticQuery(graphql`
    #graphql
    query {
      storyblok {
        BlogpostItems(published_at_gt: "1970-01-01") {
          items {
            id
            full_slug
            content {
              _uid
              _editable
              intro
              title
              img {
                alt
                name
                title
                filename
                id
                copyright
                focus
              }
            }
          }
        }
      }
    }
  `).storyblok.BlogpostItems
}

import React from "react"
import { Link as GatsbyLink } from "gatsby"

export const Link = ({ to, label, children, ...other }) => {
  const { tag: Tag, ...linkProps } = getLinkProps(to)

  return (
    <Tag {...linkProps} {...other}>
      {label || children}
    </Tag>
  )
}

function getLinkProps(to) {
  if (typeof to === "string") {
    return {
      tag: GatsbyLink,
      to,
    }
  }
  switch (to.linktype) {
    case "story": {
      return {
        tag: GatsbyLink,
        to: `${to.cached_url.startsWith("/") ? "" : "/"}${to.cached_url}`,
      }
    }
    default: {
      return {
        tag: "a",
        href: to.cached_url,
        rel: "norefferer noopener",
      }
    }
  }
}

import React from "react"
import cx from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { Img } from "../../Img"
import * as styles from "./OfferItem.module.scss"

export const OfferItem: React.VFC = ({
  title,
  img,
  footer,
  features = [],
  className,
  ...other
}) => {
  const {
    storyblok: {
      allFeatures: { items: allFeatures },
    },
  } = useStaticQuery(graphql`
    query {
      storyblok {
        allFeatures: DatasourceEntries(datasource: "pricing-features") {
          items {
            name
            value
          }
        }
      }
    }
  `)

  return (
    <div className={cx(className, "w-full py-4")} {...other}>
      <div className="p-4 w-full">
        <Img src={img} width="100%" />
        <div className="text-xl text-center text-white font-bold bg-primary rounded-full w-full  p-1 -translate-y-2/4">
          {title}
        </div>
      </div>
      <ul className="w-full px-2">
        {allFeatures.map(({ value, name }) => {
          return (
            <li
              key={name}
              className={cx(
                "list-inside relative list-none py-1",
                styles.item,
                features.includes(value)
                  ? styles.itemEnabled
                  : styles.itemDisabled
              )}
            >
              {value}
            </li>
          )
        })}
      </ul>
      <div className="w-full p-4">
        <div className="text-3xl text-center text-white font-bold bg-primary rounded-2xl w-full p-1">
          {footer}
        </div>
      </div>
    </div>
  )
}

import React from "react"
import cx from "classnames"
import { renderComponent } from "../DynamicComponent"
import { BackgroundItem } from "./BackgroundItem"

function renderItem(blok) {
  return renderComponent(BackgroundItem, blok)
}

export const BackgroundContainer: React.FC = ({
  className,
  items,
  children,
  ...other
}) => {
  return (
    <div
      className={cx(
        className,
        "absolute top-0 left-0 w-full h-full overflow-hidden"
      )}
      {...other}
    >
      {children || items.map(renderItem)}
    </div>
  )
}

import { useEffect } from "react"
import { noop } from "lodash"

import "vanilla-cookieconsent/dist/cookieconsent.css"
import { CookieConsent as CookieConsentLib } from "./lib"

import "./CookieConsent.module.scss"

export const CookieConsent = () => {
  useEffect(() => {
    const cc = CookieConsentLib()

    cc.run({
      current_lang: "pl",
      onAccept: noop,
      onChange: noop,
      onFirstAction: noop,
      gui_options: {
        consent_modal: {
          layout: "cloud", // box/cloud/bar
          position: "bottom center", // bottom/middle/top + left/right/center
          transition: "zoom", // zoom/slide
          // swap_buttons: false, // enable to invert buttons
        },
      },
      languages: {
        pl: {
          consent_modal: {
            title: "Używamy plików cookie (bezglutenowych)!",
            description:
              "Ta strona wykorzystuje ciasteczka HTTP (pliki cookie). Jeśli nie wyrażasz zgody, ustawienia dotyczące plików cookies możesz zmienić w swojej przeglądarce.",
            primary_btn: {
              text: "Rozumiem",
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
          },
          settings_modal: {
            title: "",
            save_settings_btn: "",
            accept_all_btn: "",
            reject_all_btn: "",
            close_btn_label: "",
            cookie_table_headers: [],
            blocks: [],
          },
        },
      },
    })
  }, [])

  return null
}

import React from "react"
import { sbEditable } from "storyblok"
import Placeholder from "./Placeholder"
import { useDynamicComponents } from "./DynamicComponentContext"
import { ErrorBoundary } from "./ErrorBoundary"

export function renderComponent(Component, blok) {
  const { _uuid, _editable, component, ...actualData } = blok
  return <Component {...actualData} {...sbEditable(blok)} key={blok._uid} />
}

export const DynamicComponent = ({ blok }) => {
  const getComponent = useDynamicComponents()
  const componentName = blok.component
  const Component = getComponent(componentName)
  if (typeof Component !== "undefined") {
    return (
      <ErrorBoundary blok={blok}>
        {renderComponent(Component, blok)}
      </ErrorBoundary>
    )
  }

  return componentName ? <Placeholder componentName={componentName} /> : null
}

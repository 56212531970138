import React from "react"
import type { FC, ComponentProps } from "react"
import cx from "classnames"
import { map } from "lodash/fp"
import {
  getAlignContentClass,
  getAlignItemsClass,
  getJustifyItemsClass,
} from "./helpers"
import type { JustifyItems, AlignContent, AlignItems } from "./helpers"

export type GridProps = ComponentProps<"div"> & {
  columns: number
  alignItems?: AlignItems
  justifyItems?: JustifyItems
}

const mapToFraction = map<string | number, string>(el =>
  Number.isNaN(Number(el)) ? (el as string) : `${el}fr`
)

const columnsMap = {
  1: "sm:grid-cols-1",
  2: "sm:grid-cols-2",
  3: "sm:grid-cols-3",
  4: "sm:grid-cols-4",
  5: "sm:grid-cols-5",
  6: "sm:grid-cols-6",
}

export const Grid: FC<GridProps> = ({
  columns,
  className,
  alignItems,
  justifyItems,
  ...other
}) => {
  return (
    <div
      className={cx(
        className,
        "grid grid-cols-1",
        columnsMap[columns],
        getAlignItemsClass(alignItems),
        getJustifyItemsClass(justifyItems)
      )}
      {...other}
    />
  )
}

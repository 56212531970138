import React from "react"
import type { VFC } from "react"

import { renderComponent } from "../../DynamicComponent"
import { RichText } from "../../RichText"
import { Link } from "../../Link"
import { Img } from "../../Img"
import type { BlogPostStory } from "../../BlogPost"
import { useStoryblok } from "../../../storyblok"

type SbResolvedLink<T extends object> = {
  id: string
  cached_url: string
  story: T
}

type BlogPostCardProps = {
  post: SbResolvedLink<BlogPostStory>
}

export const BlogPostCard: VFC<BlogPostCardProps> = ({ post, ...props }) => {
  const { story, ...postLink } = post

  const storyData = useStoryblok(story, post)

  if (!storyData || storyData.full_slug !== post.cached_url) {
    return null
  }

  const {
    content: { title, img, intro },
  } = storyData

  return (
    <div {...props}>
      <Link to={postLink}>
        {img && (
          <Img
            src={img}
            className="min-h-[15rem] max-h-[50vh]"
            objectFit="cover"
          />
        )}
      </Link>
      <Link to={postLink}>
        <RichText textAlign="center" className="text-primary uppercase">
          <h2>{title}</h2>
        </RichText>
      </Link>
      {renderComponent(RichText, {
        text: intro,
      })}

      <div className="text-center text-xl">
        <Link to={postLink} className="text-primary">
          Czytaj dalej {"->"}
        </Link>
      </div>
    </div>
  )
}

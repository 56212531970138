export function sbEditable(blok: any) {
  if (
    process.env.GATSBY_CONTENT_VERSION === "published" ||
    typeof blok !== "object" ||
    typeof blok._editable !== "string"
  ) {
    return {}
  }

  const options = JSON.parse(
    blok._editable.replace(/^<!--#storyblok#/, "").replace(/-->$/, "")
  )

  return {
    "data-blok-c": JSON.stringify(options),
    "data-blok-uid": options.id + "-" + options.uid,
  }
}

import { Container } from "./Container"
import { RichText } from "./RichText"
import { Img } from "./Img"
import { Flex } from "./Flex"
import { GridLayout } from "./layout/GridLayout"
import { BackgroundContainer } from "./background"
import { getDomainComponents } from "./domain"

const Components = {
  Container,
  Grid: Flex,
  RichText,
  Img,
  Flex,
  GridLayout,
  BackgroundContainer,
  ...getDomainComponents(),
}

export function getComponent(componentName: string) {
  if (!Components[componentName]) {
    console.log("Get component!", componentName, Components)
  }

  return Components[componentName]
}

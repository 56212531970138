import React from "react"
import cx from "classnames"
import { DynamicComponent, renderComponent } from "./DynamicComponent"
import { BackgroundContainer } from "./background"

const marginTopMap = {
  1: "mt-1",
  2: "mt-2",
  3: "mt-3",
  4: "mt-4",
  5: "mt-5",
  6: "mt-6",
  7: "mt-7",
  8: "mt-8",
  9: "mt-9",
  10: "mt-10",
  11: "mt-11",
  12: "mt-12",
  14: "mt-14",
  16: "mt-16",
  20: "mt-20",
}

const marginBottomMap = {
  1: "mb-1",
  2: "mb-2",
  3: "mb-3",
  4: "mb-4",
  5: "mb-5",
  6: "mb-6",
  7: "mb-7",
  8: "mb-8",
  9: "mb-9",
  10: "mb-10",
  11: "mb-11",
  12: "mb-12",
  14: "mb-14",
  16: "mb-16",
  20: "mb-20",
}

const justifyMap = {
  center: "justify-center",
  start: "justify-start",
  end: "justify-end",
}

export const Container = ({
  id,
  body,
  children,
  justify,
  height,
  className,
  marginBottom,
  marginTop,
  background,
  ...other
}) => {
  return (
    <div
      id={id}
      style={{
        minHeight: height,
      }}
      className={cx(
        "relative w-full p-6 py-10",
        className,
        marginBottomMap[marginBottom],
        marginTopMap[marginTop]
      )}
      {...other}
    >
      {background?.[0] && renderComponent(BackgroundContainer, background[0])}
      <div
        className={cx(
          "relative container max-w-screen-lg w-full h-full flex flex-wrap mx-auto z-10",
          justifyMap[justify]
        )}
      >
        {body
          ? body.map(blok => <DynamicComponent key={blok._uid} blok={blok} />)
          : children}
      </div>
    </div>
  )
}

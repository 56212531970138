import React from "react"
import { DynamicComponent } from "./DynamicComponent"

const Footer = ({ body }) => {
  return (
    <footer>
      {body &&
        body.map(blok => <DynamicComponent key={blok._uid} blok={blok} />)}
    </footer>
  )
}

export default Footer

import React, { useState } from "react"
import { Link } from "./Link"
import { Img } from "./Img"
const Nav = ({ logo, navigation }) => {
  const { items } = navigation
  const [collapsed, setCollapsed] = useState(true)

  return (
    <header className="w-full relative top-0 z-30">
      <nav className="text-black bg-primary" role="navigation">
        <div className="relative lg:container mx-auto px-3 flex flex-wrap items-center lg:flex-nowrap">
          <div className="mr-4 md:mr-8 py-2">
            <Link to="/">
              <Img src={logo} className="h-12" />
            </Link>
          </div>
          <div className="ml-auto md:hidden">
            <button
              className="flex items-center px-3 py-2 border rounded"
              type="button"
              onClick={() => {
                setCollapsed(val => !val)
              }}
            >
              <svg
                className="h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={
              `${collapsed ? "h-0" : "h-auto"} ` +
              "md:h-auto overflow-hidden w-full md:w-auto md:flex-grow md:flex md:items-center"
            }
          >
            <ul className="flex flex-col mt-2 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:mr-4 md:ml-auto lg:mr-8 md:border-0">
              {items.map((navItem, index) => (
                <li key={index}>
                  <Link
                    to={navItem.to}
                    prefetch="true"
                    className="uppercase font-bold block px-4 py-1 md:px-5"
                  >
                    {navItem.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Nav

import React from "react"
import cx from "classnames"
import { render } from "storyblok-rich-text-react-renderer"
import * as styles from "./RichText.module.scss"

const fontFamilyMap = {
  sans: "font-sans",
  serif: "font-serif",
  greatVibes: "font-greatVibes",
}

const textAlignMap = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
  justify: "text-justify",
}

const ulStyleTypeMap = {
  connectedDots: styles.ulStyleTypeConnectedDots,
}

export const RichText = ({
  text,
  children,
  className,
  font,
  textAlign,
  ulStyleType,
  fontSizeModifier = 0,
  ...other
}) => {
  return (
    <div
      className={cx(
        className,
        "w-full",
        fontFamilyMap[font],
        ulStyleTypeMap[ulStyleType],
        textAlignMap[textAlign],
        styles.editor
      )}
      style={{
        fontSize: `${1 + fontSizeModifier * 0.1}rem`,
      }}
      {...other}
    >
      {text ? render(text) : children}
    </div>
  )
}

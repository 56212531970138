import React, { createContext, useContext } from "react"
import type { VFC, ComponentProps, FC } from "react"
import { ErrorBoundary as ErrorBoundaryLib } from "react-error-boundary"
import type {
  FallbackProps,
  ErrorBoundaryPropsWithComponent,
} from "react-error-boundary"
import { sbEditable } from "storyblok"
import { prefix } from "../domain"

type SbBlok = {
  component: string
}

export type ErrorBoundaryProps = ErrorBoundaryPropsWithComponent & {
  blok: SbBlok
}

type ErrorBoundaryContextValue = SbBlok

const ErrorBoundaryContext = createContext<ErrorBoundaryContextValue>({
  component: "UnknownComponent",
})

export const ErrorBoundary: VFC<ErrorBoundaryProps> = ({ blok, ...props }) => (
  <ErrorBoundaryContext.Provider value={blok}>
    <ErrorBoundaryLib FallbackComponent={FallbackComponent} {...props} />
  </ErrorBoundaryContext.Provider>
)

const FallbackComponent: VFC<FallbackProps> = ({ resetErrorBoundary }) => {
  const blok = useContext(ErrorBoundaryContext)

  const componentName = blok.component.startsWith(prefix)
    ? blok.component.replace(prefix, "")
    : blok.component

  return (
    <div className="w-full text-center bg-red-300 p-6">
      <b>Failed to render '{componentName}' component :(</b>
      <p>
        Make sure all required fields are filled and click button below to try
        again.
      </p>
      <Button {...sbEditable(blok)}>Open story</Button>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </div>
  )
}

const Button: FC<ComponentProps<"button">> = props => (
  <button className="py-2 px-8 text-xl bg-blue-500 rounded-full" {...props} />
)

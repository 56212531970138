import {
  render,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKE,
  MARK_UNDERLINE,
  MARK_CODE,
  MARK_STYLED,
  MARK_LINK,
  NODE_HEADING,
  NODE_CODEBLOCK,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_OL,
  NODE_UL,
  NODE_LI,
  NODE_HR,
  NODE_BR,
} from "storyblok-rich-text-react-renderer"

export function sbRichTextToString(text) {
  return render(text, {
    markResolvers: {
      [MARK_BOLD]: children => children,
      [MARK_ITALIC]: children => children,
      [MARK_STRIKE]: children => children,
      [MARK_UNDERLINE]: children => children,
      [MARK_CODE]: children => children,
      [MARK_STYLED]: children => children,
      [MARK_LINK]: children => children,
    },
    nodeResolvers: {
      [NODE_HEADING]: children => children,
      [NODE_CODEBLOCK]: children => children,
      [NODE_PARAGRAPH]: children => children,
      [NODE_QUOTE]: children => children,
      [NODE_OL]: children => children,
      [NODE_UL]: children => children,
      [NODE_LI]: children => children,
      [NODE_IMAGE]: children => null,
      [NODE_HR]: children => null,
      [NODE_BR]: children => null,
    },
  })[0][0]
}

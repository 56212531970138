import { OfferItem } from "./OfferItem"
import { BlogPostCard } from "./BlogPostCard"
import { BlogPostsList } from "./BlogPostsList"

const components = {
  OfferItem,
  BlogPostCard,
  BlogPostsList
}

export const prefix = "IntoDiet"

export function getDomainComponents() {
  return Object.entries(components).reduce((acc, [name, reference]) => {
    acc[`${prefix}${name}`] = reference
    return acc
  }, {})
}

import React from "react"
import { sbEditable, useStoryblok } from "storyblok"
import Navigation from "./Navigation"
import Footer from "./Footer"
import { DynamicComponentContext } from "./DynamicComponent"
import { CookieConsent } from "./CookieConsent"
import { getComponent } from "./componentsMap"
import { useStaticQuery, graphql } from "gatsby"

export default function Layout({ children, location, lang }) {
  const {
    storyblok: { settingsStory, navigationStory },
  } = useStaticQuery(graphql`
    query Layout {
      storyblok {
        settingsStory: SettingsItem(id: "settings") {
          id
          full_slug
          content {
            _editable
            _uid
            body
            component
            logo {
              alt
              copyright
              filename
              focus
              id
              title
              name
            }
          }
        }
        navigationStory: NavigationItem(id: "navigation") {
          id
          full_slug
          content {
            _editable
            _uid
            component
            items
          }
        }
      }
    }
  `)

  const settings = useStoryblok(settingsStory)
  const navigation = useStoryblok(navigationStory)

  const isHome = location.pathname === "/"

  return (
    <DynamicComponentContext.Provider value={getComponent}>
      <div
        className="flex flex-col min-h-screen"
        {...sbEditable(settings.content)}
      >
        <CookieConsent />
        <Navigation
          navigation={navigation.content}
          logo={settings.content.logo}
          sticky={isHome}
        />
        <main className="flex-1">{children}</main>
        <Footer body={settings.content.body} />
      </div>
    </DynamicComponentContext.Provider>
  )
}

import React, { ComponentProps, useLayoutEffect, useRef } from "react"
import cx from "classnames"
import GatsbyImg from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"

const borderRadiusMap = {
  sm: "rounded-sm",
  rg: "rounded",
  md: "rounded-md",
  lg: "rounded-lg",
  xl: "rounded-xl",
  "2xl": "rounded-2xl",
  "3xl": "rounded-3xl",
  full: "rounded-full",
}

export type ImgProps = ComponentProps<"img"> & {
  src: {
    filename: string
    alt: string
  }
  rounding?: keyof typeof borderRadiusMap
  objectFit?: "contain" | "cover"
}

export const Img: React.FC<ImgProps> = props => {
  const {
    src,
    width,
    height,
    style,
    className,
    rounding,
    objectFit = "contain",
    ...other
  } = props

  const { filename, alt } = src

  const imgProps = {
    alt,
    style: {
      width: "100%",
      maxWidth: width,
      ...(height
        ? {
            height: "100vh",
            maxHeight: height,
          }
        : {}),
      ...style,
    },
    className: cx(className, borderRadiusMap[rounding] || ""),
    ...other,
  }

  if (filename.endsWith(".svg")) {
    return <img src={filename} {...imgProps} />
  }

  const fluid = getFluidGatsbyImage(filename)

  if (imgProps.style.maxHeight || imgProps.style.height) {
    // If image is based on height, the aspect ratio is not properly preserved
    // by Gatsby image
    // https://stackoverflow.com/a/68120093/5185634
    // Workaround https://github.com/facebook/react/issues/21098
    imgProps.style.stroke = fluid.aspectRatio
    imgProps.Tag = ApplyAspectRatio
  }

  return (
    <GatsbyImg
      fluid={fluid}
      imgStyle={{
        objectFit,
      }}
      {...imgProps}
    />
  )
}

// Workaround https://github.com/facebook/react/issues/21098
const ApplyAspectRatio = ({ style, ...other }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const aspectRatio = style.stroke
  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    ref.current.style.aspectRatio = aspectRatio
  }, [aspectRatio])

  return <div ref={ref} style={style} {...other} />
}

const alignContentMap = {
  center: "content-center",
  start: "content-start",
  end: "content-end",
  between: "content-between",
  around: "content-around",
  evenly: "content-evenly",
} as const

export type AlignContent = keyof typeof alignContentMap

export function getAlignContentClass(name?: AlignContent) {
  return alignContentMap[name] || alignContentMap.start
}

const alignItemsMap = {
  start: "items-start",
  end: "items-end",
  center: "items-center",
  between: "items-between",
  baseline: "items-baseline",
  stretch: "items-stretch",
} as const

export type AlignItems = keyof typeof alignItemsMap

export function getAlignItemsClass(name?: AlignItems) {
  return alignItemsMap[name] || alignItemsMap.start
}

const justifyItemsMap = {
  start: "justify-items-start",
  end: "justify-items-end",
  center: "justify-items-center",
  stretch: "justify-items-stretch",
} as const

export type JustifyItems = keyof typeof justifyItemsMap

export function getJustifyItemsClass(name?: JustifyItems) {
  return justifyItemsMap[name] || justifyItemsMap.start
}

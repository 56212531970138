import React from "react"
import cx from "classnames"
import { DynamicComponent } from "./DynamicComponent"
import { getAlignContentClass, getAlignItemsClass } from "./layout/helpers"

export const Flex = ({
  wrap = true,
  alignContent = "start",
  alignItems = "start",
  body,
  className,
  ...other
}) => {
  return (
    <div
      className={cx(
        className,
        "flex w-full",
        {
          "flex-wrap": wrap,
        },
        getAlignContentClass(alignContent),
        getAlignItemsClass(alignItems)
      )}
      {...other}
    >
      {body.map(blok => (
        <DynamicComponent key={blok._uid} blok={blok} />
      ))}
    </div>
  )
}

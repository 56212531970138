import React from "react"
import cx from "classnames"
import { capitalize } from "lodash"
import { Img } from "../Img"

type BackgroundItemProps = {
  img: any
  style: any
  sizeType: "width" | "height"
  size?: string
  minSize?: string
  rotation?: number
  left?: string
  top?: string
  right?: string
  bottom?: string
  translateX?: string
  translateY?: string
  opacity?: number
}

export const BackgroundItem: React.VFC<BackgroundItemProps> = ({
  className,
  img,
  sizeType,
  size,
  minSize,
  style,
  rotation = 0,
  left,
  top,
  right,
  bottom,
  translateX,
  translateY,
  opacity,
  ...other
}) => {
  const props = {
    className,
    style: {
      position: "absolute",
      [sizeType]: "100%",
      ...(sizeType === 'height' && {
        width: 'auto'
      }),
      [`max${capitalize(sizeType)}`]: size,
      [`min${capitalize(sizeType)}`]: minSize,
      transform: [
        translateX && `translateX(${translateX})`,
        translateY && `translateY(${translateY})`,
        rotation && `rotate(${rotation}deg)`,
      ]
        .filter(Boolean)
        .join(" "),
      left,
      top,
      right,
      bottom,
      opacity,
      ...style,
    },
    ...other,
  }
  if (img?.filename) {
    return <Img src={img} {...props} />
  }
  return null
}

import React, { useCallback, useEffect } from "react"
import { useLocation } from "@reach/router"
import { StoryData, StoryblokComponent } from "storyblok-js-client"
import StoryblokService from "./StoryblokService"

type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> &
  Pick<T, TRequired>

type StoryDataMinimal<TContent> = OptionalExceptFor<
  StoryData<TContent>,
  "content" | "full_slug"
>

type Link = {
  cached_url: string
}

export const useStoryblok = <
  TComp extends string,
  TContent extends StoryblokComponent<TComp>,
  TStoryData extends StoryDataMinimal<TContent>
>(
  initialStory: TStoryData | null,
  link?: Link
) => {
  const location = useLocation()

  const [story, setStory] = React.useState(initialStory)
  const fullSlug = link?.cached_url || story?.full_slug

  if (typeof link !== "undefined" && !fullSlug) {
    throw new Error("useStoryblok: please select link destination")
  }

  // See https://www.storyblok.com/docs/Guides/storyblok-latest-js
  const initStoryblokListeners = useCallback(() => {
    let hasInitialized = false
    let hasUnmounted = false
    // @ts-expect-error
    const { StoryblokBridge } = window

    if (!StoryblokBridge) {
      console.error("StoryblokBridge not in window")
      return
    }

    const storyblokInstance = new StoryblokBridge({
      preventClicks: true,
    })

    function handleInput(payload?: StoryblokEventPayload<any>) {
      if (hasUnmounted) {
        return
      }
      if (!payload) {
        return
      }
      const { story: payloadStory } = payload as {
        story?: StoryData<StoryblokComponent<TComp>>
      }
      if (!fullSlug || fullSlug === payloadStory.full_slug) {
        setStory(payloadStory as TStoryData)
      }
    }

    storyblokInstance.on(["published", "change"], event => {
      if (hasUnmounted) {
        return
      }
      // reload project on save an publish
      window.location.reload()
    })

    storyblokInstance.on("input", handleInput)

    function handleEnterEditMode(event) {
      // all fo the instances of Storyblok bridge receives the event
      // whenever any of it is created
      // It needs to be guarded to make sure this is executed
      // only once per listener
      if (hasInitialized) {
        return
      }
      hasInitialized = true
      // loading the draft version on initial view of the page
      StoryblokService.get(`cdn/stories/${fullSlug || event.storyId}`, {
        version: "draft",
      })
        .then(({ data }) => {
          if (hasUnmounted) {
            return
          }
          if (data.story) {
            setStory(data.story)
          }
        })
        .catch(error => {
          if (hasUnmounted) {
            return
          }
          console.log("Error loading initial SB data", error)
        })
    }

    storyblokInstance.on("enterEditmode", handleEnterEditMode)

    return () => {
      hasUnmounted = true
    }
  }, [fullSlug])

  useEffect(() => {
    // load bridge only inside the storyblok editor
    if (location.search.includes("_storyblok")) {
      console.info("In storyblok editor. Attaching listeners...")
      // first load the bridge and then attach the events
      addBridge(initStoryblokListeners)
    }
  }, [initStoryblokListeners])

  return story
}

let bridgePromise = null

async function addBridge(callback) {
  // check if the script is already present
  if (!bridgePromise) {
    bridgePromise = new Promise<void>((resolve, reject) => {
      const script = document.createElement("script")
      script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`
      script.id = "storyblokBridge"
      document.body.appendChild(script)
      script.onload = () => {
        // call a function once the bridge is loaded
        resolve()
      }
      script.onerror = reject
    })
  }

  await bridgePromise

  callback()
}

import React from "react"
import type { FC } from "react"
import cx from "classnames"

import { Grid } from "./Grid"
import type { GridProps } from "./Grid"
import { GridItem } from "./GridItem"
import { DynamicComponent } from "../DynamicComponent"

export type GridItemOptions = {}

export type GridLayoutProps = GridProps & {
  body?: any
  //   defaultGridItems?: Array<GridItemOptions>
  //   gridItems?: Array<GridItemOptions>
}

export const GridLayout: FC<GridLayoutProps> = props => {
  const {
    gridItems,
    defaultGridItem,
    children,
    body,
    className,
    gap,
    style,
    ...other
  } = props

  return (
    <Grid
      className={cx(className, "w-full")}
      style={{
        gap,
        ...style,
      }}
      {...other}
    >
      {mapItems(children, body, (child, index) => {
        const itemOptions = gridItems?.[index]

        return (
          <GridItem key={index} {...defaultGridItem} {...itemOptions}>
            {child}
          </GridItem>
        )
      })}
    </Grid>
  )
}

const mapItems = (children, body, mapFn) => {
  if (children) {
    return React.Children.map(children, (child, index) => {
      if (!child) {
        return null
      }

      return mapFn(child, index)
    })
  }

  return body.map((blok, index) =>
    mapFn(<DynamicComponent blok={blok} />, index)
  )
}
